import get from './get.js';
import fetch from './fetch.js';
import post from './post.js';

export default {
	get: ({ session }) => get('organization', { session }),
	get_public: () => get('organization/public'),
	set: ({
		session,
		organization,
		name,
		logo,
		address1,
		address2,
		city,
		province,
		country,
		postal,
		phone,
		email,
		hours,
		availability,
		availability_min,
		auto_approve,
		edit_times,
		reminders,
		email_notifications,
		feedback,
		notification_emails = [],
	}) => {
		const body = new FormData();
		body.append('session', session);
		body.append('organization', organization); //_id
		body.append('name', name);
		if (logo === false || logo) body.append('logo', logo);
		body.append('address1', address1);
		body.append('address2', address2);
		body.append('city', city);
		body.append('province', province);
		body.append('country', country);
		body.append('postal', postal);
		body.append('phone', phone);
		body.append('email', email);
		body.append('hours', JSON.stringify(hours)); //TODO it's an Array
		body.append('availability', availability);
		body.append('availability_min', availability_min);
		body.append('auto_approve', auto_approve);
		body.append('edit_times', edit_times);
		body.append('feedback', feedback);
		body.append('reminders', JSON.stringify(reminders)); //TODO it's an Array
		body.append('email_notifications', JSON.stringify(email_notifications)); //TODO it's an Object
		body.append('notification_emails', JSON.stringify(notification_emails)); //TODO it's an Array

		return fetch('organization', body);
	},
	file_upload: ({ session, organization, file_upload }) => post('organization/file_upload', { session, organization, file_upload }),
	feedback_add: ({ session, organization, add, text }) => post('organization/feedback_add', { session, organization, add, text }),
};
