import React, { useState, useEffect, useContext } from 'react';
import Loader from '#components/loader.jsx';
import Dropdown from '#components/dropdown.jsx';
import { prettier } from '#components/time_helpers.js';
import OrganizationContext from '#context/organization.jsx';
import ModalsContext from '#context/modals';

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const _days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const _reminder_labels = ['day', 'week', 'month'];

const _pretty_time = (time) => {
	if (time === -1) return 'N/A';
	return prettier(time);
};

const _generate_list = (from = 0, to = 24.5, chosen) => {
	if (from < 0 || to < 0) {
		from = 0;
		to = 24.5;
	}

	const list = [{ view: 'N/A', value: -1, chosen: chosen === -1 }];

	for (let i = from; i < to; i += 0.5) list.push({ view: prettier(i), value: i, chosen: chosen === i });

	return list;
};

function FacilityView() {
	const [data, set_data] = useState();
	const [preview, set_preview] = useState('');
	const [selected_file, set_selected_file] = useState();

	const modals_context = useContext(ModalsContext);
	const organization_context = useContext(OrganizationContext);

	useEffect(() => {
		(async () => {
			const result = await organization_context.get();
			const hours = result.hours.map((el) => {
				if (el[0] === 0 && el[1] === 0) return [-1, -1];
				return [...el];
			});
			set_data({ ...result, hours });
			set_selected_file(data?.logo);
		})();
	}, []);

	useEffect(() => {
		set_preview(data?.logo);
	}, [data?.logo]);

	if (!data) return <Loader />;

	const handle_change = (e) => {
		const { name, value } = e.target;
		set_data({ ...data, [name]: value });
	};

	const handle_change_checked = (e, i) => {
		const { name, checked } = e.target;

		const new_data = { ...data };

		if (name.startsWith('reminder')) {
			new_data.reminders[i] = checked;
		} else if (name.startsWith('email_notification')) {
			if (!new_data.email_notifications) new_data.email_notifications = {};
			new_data.email_notifications[i] = checked;
		} else {
			new_data[name] = checked;
		}

		set_data(new_data);
	};

	const handle_change_dropdown = (day_index, field, value) => {
		const new_hours = [...data.hours];
		const current_day = new_hours[day_index + 1];

		current_day[field] = value;

		if (value === -1) {
			current_day[field === 0 ? 1 : 0] = -1;
		} else if (current_day[1 - field] === -1) {
			current_day[1 - field] = field === 0 ? 24 : 0;
		}

		set_data({ ...data, hours: new_hours });
	};

	const handle_submit = async (e) => {
		e.preventDefault();

		const hours = data.hours.map((el) => {
			if (el[0] === -1 || el[1] === -1) return [0, 0];
			return [...el];
		});
		const new_data = { ...data, hours };

		if (selected_file === false || (selected_file && selected_file !== new_data.logo)) {
			new_data.logo = selected_file;
		} else {
			delete new_data.logo;
		}

		try {
			organization_context.set(new_data);
			modals_context.show('saved_changes');
		} catch (e) {
			console.error(e);
		}
	};

	const handle_change_file = (event) => {
		const file = event.target.files[0];
		set_selected_file(file);

		const fileUrl = URL.createObjectURL(file);
		set_preview(fileUrl);
	};

	return (
		<section className="dashboard_list facility_details">
			<div className="header_block">
				<h1>Facility Details</h1>
			</div>

			<form onSubmit={handle_submit}>
				<div className="block basic">
					<div className="input">
						<label htmlFor="name">Facility Name*</label>
						<input type="text" name="name" value={data.name} onChange={handle_change} required />
					</div>

					<label htmlFor="logo">Logo (Recommended size: 100px width x 45px height)</label>

					<div className="added_logo">
						{preview ? (
							<>
								<img src={preview} alt="logo" />
								<div className="actions">
									<a className="icon border refresh">
										<label>
											<input type="file" name="logo" onChange={handle_change_file} />
										</label>
									</a>
									<a
										className="icon border delete"
										onClick={() => {
											set_selected_file(false);
											set_preview('');
										}}
									/>
								</div>
							</>
						) : (
							<a className="button">
								<label>
									<input type="file" name="logo" onChange={handle_change_file} />
									Select Image
								</label>
							</a>
						)}
					</div>
				</div>

				<div className="block">
					<h3>Contact Information</h3>

					<div className="input">
						<label htmlFor="address1">Street Address*</label>
						<input type="text" name="address1" value={data.address1} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="address2">Address Line 2</label>
						<input type="text" name="address2" value={data.address2} onChange={handle_change} />
					</div>

					<div className="input">
						<label htmlFor="city">City*</label>
						<input type="text" name="city" value={data.city} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="province">State/Province*</label>
						<input type="text" name="province" value={data.province} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="country">Country*</label>
						<input type="text" name="country" value={data.country} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="postal">Postal Code*</label>
						<input type="text" name="postal" value={data.postal || ''} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="phone">Phone Number</label>
						<input type="text" name="phone" value={data.phone} onChange={handle_change} />
					</div>

					<div className="input">
						<label htmlFor="email">Email*</label>
						<input type="email" name="email" value={data.email} onChange={handle_change} required />
					</div>

					<div className="input">
						<label htmlFor="notification_emails">Additional Email Address(es) for Notifications</label>
						<ReactMultiEmail
							name="notification_emails"
							emails={data.notification_emails}
							onChange={(value) => handle_change({ target: { name: 'notification_emails', value, required: false } })}
							autoFocus={false}
							getLabel={(email, index, remove) => {
								return (
									<div data-tag key={index}>
										<div data-tag-item>{email}</div>
										<span data-tag-handle onClick={() => remove(index)}>
											×
										</span>
									</div>
								);
							}}
						/>
					</div>
				</div>

				<div className="block schedule">
					<h3>Hours of Operation</h3>
					<p className="description">Set the hours that your facility is available for booking requests</p>

					<dl>
						<dt>Day</dt>
						<dd>Hours</dd>

						{_days.map((day, i) => {
							const [open, close] = data.hours[i + 1];

							return (
								<React.Fragment key={i}>
									<dt>{day}</dt>
									<dd>
										<Dropdown
											selection={_pretty_time(open)}
											selected={true}
											list={_generate_list(undefined, close, open)}
											clb={(value) => handle_change_dropdown(i, 0, value)}
										/>
										<span>-</span>
										<Dropdown
											selection={_pretty_time(close)}
											selected={true}
											list={_generate_list((open === -1 ? 0 : open) + 0.5, undefined, close)}
											clb={(value) => handle_change_dropdown(i, 1, value)}
										/>
									</dd>
								</React.Fragment>
							);
						})}
					</dl>
				</div>

				<div className="block in_row">
					<div className="input">
						<label htmlFor="availability">
							<h3>Users Can't Make Requests Within This Many Business Days:</h3>
							<p className="description">Minimum time allowed for booking into the future</p>
						</label>

						<input type="number" name="availability_min" minimum="0" required value={data.availability_min || 0} onChange={handle_change} />
					</div>
				</div>

				<div className="block in_row">
					<div className="input">
						<label htmlFor="availability">
							<h3>Users Can't Make Requests Beyond This Many Months:</h3>
							<p className="description">Maximum time allowed for booking into the future</p>
						</label>

						<input type="number" name="availability" minimum="1" required value={data.availability} onChange={handle_change} />
					</div>
				</div>

				<div className="block">
					<div className="checkbox">
						<input type="checkbox" id="auto_approve" name="auto_approve" checked={data.auto_approve} onChange={handle_change_checked} />
						<label htmlFor="auto_approve">Auto-Approve Booking Requests</label>
					</div>
					<p className="description">Automatically approve session requests when a user submits them bypassing pending mode</p>
				</div>

				<div className="block">
					<div className="checkbox">
						<input type="checkbox" id="edit_times" name="edit_times" checked={data.edit_times} onChange={handle_change_checked} />
						<label htmlFor="edit_times">Allow Users to Edit Setup/Cleanup Time</label>
					</div>

					<p className="description">Enabling this will allow users to edit the default setup/cleanup times during the booking process</p>
				</div>

				<div className="block">
					<h3>Booking Reminder Email</h3>
					<p className="description">Automatically email the requester reminders of their session:</p>
					<div className="checkboxes">
						{data.reminders.map((item, i) => (
							<div className="checkbox" key={i}>
								<input type="checkbox" id={`reminder${i}`} name={`reminder${i}`} checked={item} onChange={(e) => handle_change_checked(e, i)} />
								<label htmlFor={`reminder${i}`}>1 {_reminder_labels[i]} before</label>
							</div>
						))}
					</div>
				</div>

				<div className="block">
					<div className="checkbox">
						<input type="checkbox" id="feedback" name="feedback" checked={data.feedback} onChange={handle_change_checked} />
						<label htmlFor="feedback">Feedback Form Email</label>
					</div>
					<p className="description">Automatically email the requester the feedback form after their session</p>
				</div>

				<div className="block">
					<h3>Email Notifications</h3>
					<p className="description">Automatically email the facility's email address when requests are:</p>
					<div className="checkboxes">
						<div className="checkbox" key="email_notification_created">
							<input
								type="checkbox"
								id="email_notification_created"
								name="email_notification_created"
								checked={data.email_notifications?.created}
								onChange={(e) => handle_change_checked(e, 'created')}
							/>
							<label htmlFor="email_notification_created">Created</label>
						</div>
						<div className="checkbox" key="email_notification_cancelled">
							<input
								type="checkbox"
								id="email_notification_cancelled"
								name="email_notification_cancelled"
								checked={data.email_notifications?.cancelled}
								onChange={(e) => handle_change_checked(e, 'cancelled')}
							/>
							<label htmlFor="email_notification_cancelled">Cancelled</label>
						</div>
					</div>
				</div>

				<div className="actions">
					<button
						type="reset"
						onClick={async () => {
							set_data(await organization_context.get());
							set_preview(data?.logo);
						}}
					>
						Discard Changes
					</button>
					<button type="submit">Save Changes</button>
				</div>
			</form>
		</section>
	);
}

export default FacilityView;
