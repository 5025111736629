import post from './post.js';
import get from './get.js';
import fetch from './fetch.js';

export default {
	public_calendar: (query) => get('bookings/public_calendar', query),
	timeslots: ({ year, month, rooms, duration, setup, cleanup }) => post('bookings/timeslots', { year, month, rooms, duration, setup, cleanup }),
	create: ({ session, setup, cleanup, duration, form_data, custom_data, timeslots }) =>
		post('bookings/create', { session, setup, cleanup, duration, form_data, custom_data, timeslots }),
	create_files: ({ session, file, booking_ids }) => {
		const body = new FormData();
		body.append('file', file);
		body.append('booking_ids', JSON.stringify(booking_ids));
		if (session) body.append('session', session);

		return fetch('bookings/create_files', body);
	},
	requests: ({ session, status, year, month }) => get('bookings/requests', { session, status, year, month }),
	get: ({ session, booking }) => get('bookings/get', { session, booking }),
	update_status: ({ session, booking, status, note }) => post('bookings/update/status', { session, booking, status, note }),
	add_note: ({ session, booking, note }) => post('bookings/update/add_note', { session, booking, note }),
	add_file: ({ session, booking, file }) => {
		const body = new FormData();
		body.append('file', file);
		body.append('booking', booking);
		body.append('session', session);

		return fetch('bookings/update/add_file', body);
	},
	delete_file: ({ session, booking, file }) => post('bookings/update/delete_file', { session, booking, file }),
	analytics: ({ session, from, to }) => get('bookings/analytics', { session, from, to }),

	block_time: ({ session, date, time, name, rooms, analytics }) => post('bookings/block_time', { session, date, time, name, rooms, analytics }),

	export_ics: ({ session, from, to }) => get('bookings/export/ics', { session, from, to }),
	export_xlsx: ({ session, from, to, fields }) => post('bookings/export/xlsx', { session, from, to, fields }),

	admin_calendar: ({ session, date }) => get('bookings/admin_calendar', { session, date }),

	update_request: ({ session, booking, name, email, notification_emails, phone, session_name, attendees, details }) =>
		post('bookings/update/request', { session, booking, name, email, notification_emails, phone, session_name, attendees, details }),
	update_custom: ({ session, booking, info }) => post('bookings/update/custom', { session, booking, info }),
	update_schedule: ({ session, booking, date, start, setup, duration, cleanup, rooms, analytics }) =>
		post('bookings/update/schedule', { session, booking, date, start, setup, duration, cleanup, rooms, analytics }),
	update_assets: ({ session, booking, assets }) => post('bookings/update/assets', { session, booking, assets }),
	asset_calendar: ({ session, year, month, assets }) => post('bookings/asset_calendar', { session, year, month, assets }),
};
